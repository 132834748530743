<script setup>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import axios from 'axios'
import { VDataTable } from 'vuetify/labs/VDataTable'

import RegistAuctioneersForm from './forms/RegistAuctioneersForm.vue'

const apiDomain = 'https://api.minfuu.jp'
let maxNumber = 0
const router = useRouter()
const store = useStore()
const participants = ref([]) // 出欠boolean
const auctioneersList = ref([]) // 顧客リスト
const edittingAuctioneer = ref(null)
const isLoading = ref(false)
const showDialog = ref(false) // ダイアログ表示
const headers = [
  { title: '番号', key: 'number' },
  { title: '古物商許可番号', key: 'lisence_number' },
  { title: '名称／氏名', align: 'start', sortable: false, key: 'name' },
  { title: '略名称', align: 'start', sortable: false, key: 'view_name' },
  { title: 'よみがな', key: 'reading' },
  { title: '郵便番号', key: 'postal_code' },
  { title: '住所', key: 'address' },
  { title: '電話', key: 'phone' },
  { title: 'メール', key: 'email' },
  { title: 'インボイス', key: 'tax_code' },
  { title: '操作', key: 'actions' }
]

const handleApiError = (error) => {
  console.log('############################')
  console.log(error)
  console.log('############################')
  router.push('/login')
  // throw new Error('APIエラーが発生しました')
}

const updateAuctioneerList = async () => {
  isLoading.value = true
  const res = await axios.get(`${apiDomain}/auctions/${store.state.auctionId}/participants`, { params: { only_participants: false }, withCredentials: true })
    .then((response) => {
      isLoading.value = false
      return response.data
    }).catch((error) => {
      isLoading.value = false
      handleApiError(error)
    })
  auctioneersList.value = res
  participants.value = res.filter(x => x.is_participant).map(x => x.id)
  maxNumber = auctioneersList.value.slice(-1)[0].number
}
const updateParticipants = async () => {
  await axios.put(`${apiDomain}/auctions/${store.state.auctionId}/participants`, { participant_ids: participants.value }, { withCredentials: true })
    .then((response) => response.data)
    .catch((error) => {
      handleApiError(error)
    })
  router.push('/auctionitems')
}
const deleteAuctioneer = async (id) => {
  await axios.delete(`${apiDomain}/auctioneers/${id}`, { withCredentials: true })
    .then((response) => response.data)
    .catch((error) => {
      handleApiError(error)
    })
  updateAuctioneerList()
}
const registAuctioneer = () => {
  edittingAuctioneer.value = {
    id: -1,
    number: maxNumber + 1,
    name: '',
    viewName: '',
    reading: '',
    postal: '',
    address: '',
    phone: '',
    email: '',
    taxCode: '',
    lisenceNumber: ''
  }
  showDialog.value = true
}
const editAuctioneer = (item) => {
  edittingAuctioneer.value = {
    id: item.id,
    number: item.number,
    name: item.name,
    viewName: item.view_name,
    reading: item.reading,
    postal: item.postal_code,
    address: item.address,
    phone: item.phone,
    email: item.email,
    taxCode: item.tax_code,
    lisenceNumber: item.lisence_number
  }
  showDialog.value = true
}
const deleteItem = (item) => {
  if (window.confirm('本当に削除しますか？')) {
    deleteAuctioneer(item.id)
    updateAuctioneerList()
  }
}
const closeDialog = () => {
  showDialog.value = false
  updateAuctioneerList()
}
updateAuctioneerList()
</script>

<template>
  <v-container>
    <v-row justify="center">
      <v-col>
        <v-card>
          <v-card-title>参加者登録</v-card-title>
          <v-card-subtitle>参加する方にチェックを付けてください</v-card-subtitle>
          <v-card-actions>
            <v-row>
              <v-col>
                <v-btn variant="elevated" prepend-icon="mdi-account-multiple-check" size="x-large" color="primary" @click="updateParticipants">参加状況を保存して次へ</v-btn>
                <v-btn variant="outlined" prepend-icon="mdi-account-plus" size="x-large" color="primary" @click="registAuctioneer">新規顧客登録</v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
          <v-card-text>
            <v-data-table class="w-auto elevation-1 overflow-y-auto" height="400" v-model="participants" items-per-page="-1" :headers="headers" :items="auctioneersList" item-value="id" show-select disable-pagination fixed-header>
              <template #[`column.data-table-select`]></template>
              <template v-slot:top>
                <v-dialog width="60%" min-width="400" v-model="showDialog">
                  <RegistAuctioneersForm :auctioneer="edittingAuctioneer" @close="closeDialog"></RegistAuctioneersForm>
                </v-dialog>
              </template>
              <template #[`item.actions`]="{ item }">
                <v-icon size="small" class="me-2" @click="editAuctioneer(item.raw)">mdi-pencil</v-icon>
                <v-icon size="small" @click="deleteItem(item.raw)">mdi-delete</v-icon>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
        <v-dialog v-model="isLoading" persistent width="auto" class="spinner-dialog">
          <v-card>
            <v-card-text>
              <v-row justify="center">
                <v-progress-circular color="primary" indeterminate size="64"></v-progress-circular>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped>
.spinner-dialog.v-dialog .v-card {
  overflow: hidden !important;
  background-color: transparent !important;
  box-shadow: none !important;
}
.spinner-dialog.v-dialog {
  background-color: rgba(0, 0, 0, 0.5) !important;
}
</style>

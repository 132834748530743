<script setup>
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { useDisplay } from 'vuetify'

import MainBreadcrumbs from '../components/parts/MainBreadcrumbs.vue'

const { name } = useDisplay()
const height = computed(() => {
  switch (name.value) {
    case 'xs': return 220
    case 'sm': return 400
    case 'md': return 500
    case 'lg': return 600
    case 'xl': return 800
    case 'xxl': return 1200
  }
  return undefined
})
const store = useStore()
const router = useRouter()
const showDrawer = ref(false)
const sidebarLinks = [
  {
    title: '市場選択',
    to: '/auctions',
    icon: 'mdi-domain',
    requireSelectedAuction: false
  },
  {
    title: '参加者',
    to: '/participants',
    icon: 'mdi-account-multiple',
    requireSelectedAuction: true
  },
  {
    title: '取引',
    to: '/auctionitems',
    icon: 'mdi-swap-horizontal',
    requireSelectedAuction: true
  },
  {
    title: '帳票',
    to: '/reports',
    icon: 'mdi-file-document-outline',
    requireSelectedAuction: true
  }
]
const footerLinks = [
  {
    title: '各種設定',
    to: '/configure',
    icon: 'mdi-cog-outline'
  },
  {
    title: 'お問合せ',
    to: '/contact',
    icon: 'mdi-file-document-outline'
  },
  {
    title: 'パスワード変更',
    to: '/change-pass',
    icon: 'mdi-lock-reset'
  },
  {
    title: 'ログイン画面',
    to: '/login',
    icon: 'mdi-logout'
  }
]
const logout = () => {
  store.dispatch('logoff')
  router.push('/')
}
const isAdmin = store.state.isAdmin
const viewLinks = computed(() => sidebarLinks.filter(x => store.state.auctionId !== '' || !x.requireSelectedAuction))

</script>

<template>
  <v-app>
    <v-app-bar color="primary" app dark class="px-md-6">
      <v-app-bar-nav-icon @click="showDrawer=!showDrawer"></v-app-bar-nav-icon>
      <v-app-bar-title>
        <span>{{ store.state.auctionTitle }}</span>
      </v-app-bar-title>
    </v-app-bar>
    <v-navigation-drawer v-model="showDrawer" temporary>
      <v-list>
        <v-list-item v-for="item in viewLinks" :key="item.to" :to="item.to">
          <v-list-item-title class="font-weight-bold" v-text="item.title" />
          <template v-slot:prepend>
            <v-icon :icon="item.icon" class="mr-6" />
          </template>
        </v-list-item>
      </v-list>
      <template v-slot:append>
        <v-btn v-if="isAdmin" prepend-icon="mdi-account-star" block to="/admin">管理者ページ</v-btn>
        <v-btn prepend-icon="mdi-cog-outline" block to="/configure">各種設定</v-btn>
        <v-btn prepend-icon="mdi-lock-reset" block to="/change-pass">パスワード変更</v-btn>
        <v-btn prepend-icon="mdi-logout" block @click="logout">ログアウト</v-btn>
      </template>
    </v-navigation-drawer>
    <v-main class="px-6 mt-6 pt-10">
      <MainBreadcrumbs></MainBreadcrumbs>
      <v-container>
        <v-row :height="height">
          <v-col>
            <router-view></router-view>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <v-footer color="secondary">
      <v-row justify="center">
        <v-btn v-for="link in footerLinks" :key="link" :prepend-icon="link.icon" :to="link.to" variant="text" class="mx-2" rounded="xl">
          {{ link.title }}
        </v-btn>
        <v-col class="text-center" cols="12">
          <span>{{ new Date().getFullYear() }} - 合同会社 みんふう楽器店</span>
        </v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>

<script setup>
import { useStore } from 'vuex'

const store = useStore()
const cardHeight = 250
const serviceList = [
  {
    title: '無制限会場登録',
    subTitle: 'いくつでも会場を登録可能です'
  },
  {
    title: '無制限取引登録',
    subTitle: '取引件数に制限はありません'
  },
  {
    title: '無制限参加者登録',
    subTitle: '多数の参加者にも対応しています'
  },
  {
    title: '24時間以内のメールサポート',
    subTitle: '何回でもお問い合わせください'
  }
]
</script>

<template>
  <section id="hero">
    <v-row justify="center">
      <v-col cols="12" md="10">
        <v-img :src="require('@/assets/26167925_m.jpg')">
          <v-sheet color="transparent" height="100%" class="d-flex justify-end align-end align-sm-center">
            <v-card color="#FFFFFF66" class="px-8 py-5 text-right">
              <v-card-title><h1 class="text-h6 text-sm-h4 font-weight-bold">{{ store.state.appName }}</h1></v-card-title>
              <v-card-subtitle><span>帳場にゆとりを</span></v-card-subtitle>
              <v-card-actions>
                <v-row>
                  <v-col justify="end">
                    <v-btn variant="elevated" color="primary" href="#features">特徴を見る</v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-card>
          </v-sheet>
        </v-img>
      </v-col>
    </v-row>
  </section>

  <section id="features">
    <v-container>
      <v-row>
        <v-col justify="center">
          <v-sheet class="mt-2 mb-4 px-3 py-6" color="info">
            <v-card color="transparent" variant="flat">
              <v-card-title class="text-center mb-6"><h3 class="text-h5 font-weight-black">古物市場の帳場は大変</h3></v-card-title>
              <v-card-text class="text-body-1 text-justify">
                <p class="mb-3">古物市場での帳場業務には、数多くの書類の記入や伝票作成が必要です。<br>正確さのみならずスピードまでも求められる中での手作業は、ミスが生じやすく、手間もかかるため、その効率化は大きな課題です。</p>
                <p class="mb-3">そこで、私たちは、古物市場での帳場業務を簡素化できるソフトウェアを開発しました。<br>簡単に取引を登録し、インボイス制度にも対応した取引明細表を作成することができます。<br>それにより手間やミスを減らし、作業時間を大幅に短縮することが可能となりました。</p>
                <p>また、使いやすいことを第一に考えて作成してありますので、操作方法はシンプルです。<br>初めての方でも、手作業と同じ流れで作業可能です。<br>これにより、帳場において、作業に集中することができます。</p>
              </v-card-text>
            </v-card>
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>

    <h2 class="text-center mb-6">{{ store.state.appName }}の特徴</h2>
    <v-container fluid>
      <v-row align="center">
        <v-col cols="12" sm="4" class="bg-info">
          <v-card :height="cardHeight" color="transparent" variant="flat">
            <v-card-title>
              <v-icon size="40" color="primary">mdi-receipt-text-check</v-icon>
              <h3 class="text-h5 text-sm-h6 text-md-h5 font-weight-bold">インボイス制度完全対応</h3>
            </v-card-title>
            <v-card-text class="text-body-1 text-justify">
              <p>媒介者交付特例に対応した取引明細を発行できるため、消費税申告の際に仕入税額控除が可能です。</p>
              <a target="_blank" href="https://www.nta.go.jp/taxes/shiraberu/zeimokubetsu/shohi/keigenzeiritsu/pdf/qa/01-08.pdf" rel="noreferrer noopener">媒介者交付特例についてはこちら</a>
              <span></span>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="4" class="bg-secondary">
          <v-card :height="cardHeight" color="transparent" variant="flat">
            <v-card-title>
              <v-icon size="40" color="primary">mdi-currency-jpy</v-icon>
              <h3 class="text-h5 text-sm-h6 text-md-h5  font-weight-bold">シンプルな入力と自動計算</h3>
            </v-card-title>
            <v-card-text class="text-body-1 text-justify">
              <p>参加者毎の売り買い合計、歩(手数料)、支払受取額が自動計算されるため、計算間違いの心配はありません。</p>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="4" class="bg-primary">
          <v-card :height="cardHeight" color="transparent" variant="flat">
            <v-card-title>
              <v-icon size="40" color="white">mdi-file-chart</v-icon>
              <h3 class="text-h5 text-sm-h6 text-md-h5 font-weight-bold">各種の帳票自動生成</h3>
            </v-card-title>
            <v-card-text class="text-body-1 text-justify">
              <p>取引入力が済めば自動的に作成可能になるため、忙しい古物市場の帳場での業務効率化につながります。</p>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="12" sm="6">
          <v-btn block depressed color="info" variant="elevated" to="/regist">今すぐ無料登録</v-btn>
        </v-col>
      </v-row>
    </v-container>

    <v-container class="">
      <h2 class="text-center mb-6">{{ store.state.appName }}の出力帳票サンプル</h2>
      <v-row>
        <v-col cols="12" md="4">
          <v-card>
            <v-card-title class="text-center"><h4>取引明細表</h4></v-card-title>
            <v-card-text>
              <v-img :src="require('@/assets/report_detail.png')"></v-img>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="4">
          <v-card>
            <v-card-title class="text-center"><h4>金銭受払管理表</h4></v-card-title>
            <v-card-text>
              <v-img :src="require('@/assets/report_billing.png')"></v-img>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="4">
          <v-card>
            <v-card-title class="text-center"><h4>品札</h4></v-card-title>
            <v-card-text>
              <v-img :src="require('@/assets/report_item.png')"></v-img>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </section>

  <section id="pricing">
    <v-container class="bg-accent mt-10" fluid>
      <h2 class="text-center mb-6">料金体系</h2>
      <v-row class="pb-6" justify="center">
        <v-col cols="9" md="4" class="mb-4">
          <v-card>
            <v-card-title class="bg-primary white--text text-center py-5">
              <h3>月額プラン</h3>
            </v-card-title>
            <v-card-subtitle class="text-center">
              <h4 class="my-4 text-h3">¥1,000/月</h4>
            </v-card-subtitle>
            <v-card-text>
              <v-list lines="one">
                <v-list-item v-for="item in serviceList" :key="item.title" :title="item.title" :subtitle="item.subTitle"></v-list-item>
              </v-list>
            </v-card-text>
            <v-card-actions>
              <v-btn block depressed color="info" variant="elevated" to="/regist">今すぐ無料登録</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="9" md="4" class="mb-4">
          <v-card>
            <v-card-title class="bg-secondary white--text text-center py-5">
              <h3>年額プラン</h3>
            </v-card-title>
            <v-card-subtitle class="text-center">
              <h4 class="my-4 text-h3">¥10,000/年</h4>
            </v-card-subtitle>
            <v-card-text>
              <v-list lines="one">
                <v-list-item v-for="item in serviceList" :key="item.title" :title="item.title" :subtitle="item.subTitle"></v-list-item>
              </v-list>
            </v-card-text>
            <v-card-actions>
              <v-btn block depressed color="info" variant="elevated" to="/regist">今すぐ無料登録</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </section>
  <section id="contact">
    <v-container class="bg-background-secondary">
    </v-container>
  </section>
</template>

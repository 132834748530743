<script setup>
import { ref, defineEmits } from 'vue'
import { useRouter } from 'vue-router'
import axios from 'axios'

const apiDomain = 'https://api.minfuu.jp'

const router = useRouter()
const emit = defineEmits(['close'])
const userId = ref('')
const password = ref('')
const clientsId = ref('')
const clients = ref([])
const isLoading = ref(false)

const handleApiError = (error) => {
  console.log('############################')
  console.log(error)
  console.log('############################')
  router.push('/login')
  // throw new Error('APIエラーが発生しました')
}

const updateClientsList = async () => {
  isLoading.value = true
  const res = await axios.get(`${apiDomain}/clients`, { withCredentials: true })
    .then((response) => {
      isLoading.value = false
      return response.data
    }).catch((error) => {
      isLoading.value = false
      handleApiError(error)
    })
  clients.value = res
  clientsId.value = clients.value[0].id
}
const save = async () => {
  const data = {
    user_id: userId.value,
    password: password.value,
    clients_id: clientsId.value
  }
  await axios.post(`${apiDomain}/admin/users`, data, { withCredentials: true })
    .then((response) => response.data)
    .catch((error) => {
      handleApiError(error)
    })
  emit('close')
}
updateClientsList()
</script>

<template>
  <v-card min-width="300">
    <v-card-title><h2>ユーザー登録</h2></v-card-title>
    <v-card-text>
      <v-text-field v-model="userId" label="ユーザーID" variant="outlined"></v-text-field>
      <v-text-field v-model="password" label="パスワード" variant="outlined"></v-text-field>
      <v-select variant="outlined" v-model="clientsId" label="顧客選択" :items="clients" item-title="name" item-value="id">
        <template #append>
          <v-progress-circular v-if="isLoading" color="primary" size="24"></v-progress-circular>
        </template>
      </v-select>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn variant="outlined" prepend-icon="mdi-storefront-plus" size="x-large" color="primary" block @click="save">登録</v-btn>
   </v-card-actions>
  </v-card>
</template>

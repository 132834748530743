<script setup>
import { ref, defineEmits, defineProps } from 'vue'
import { useRouter } from 'vue-router'
import axios from 'axios'

const apiDomain = 'https://api.minfuu.jp'

const router = useRouter()
const props = defineProps({
  auction: Object
})
const emit = defineEmits(['close'])
const auctionDate = ref(props.auction.auctionDate)
const name = ref(props.auction.name)
const lisenceNumber = ref(props.auction.lisenceNumber)
const sellingMarginRate = ref(props.auction.sellingMarginRate)
const buyingMarginRate = ref(props.auction.buyingMarginRate)

const handleApiError = (error) => {
  console.log('############################')
  console.log(error)
  console.log('############################')
  router.push('/login')
  // throw new Error('APIエラーが発生しました')
}
const updateAuction = async () => {
  const data = {
    id: props.auction.id,
    auction_date: auctionDate.value,
    name: name.value,
    selling_margin_rate: sellingMarginRate.value,
    buying_margin_rate: buyingMarginRate.value,
    lisence_number: lisenceNumber.value
  }
  const res = await axios.put(`${apiDomain}/auctions/${props.auction.id}`, data, { withCredentials: true })
    .then((response) => response.data)
    .catch((error) => {
      handleApiError(error)
    })
  return res.id
}

const registAuction = async () => {
  const data = {
    auction_date: auctionDate.value,
    name: name.value,
    lisence_number: lisenceNumber.value,
    selling_margin_rate: sellingMarginRate.value,
    buying_margin_rate: buyingMarginRate.value
  }
  const res = await axios.post(`${apiDomain}/auctions`, data, { withCredentials: true })
    .then((response) => response.data)
    .catch((error) => {
      handleApiError(error)
    })
  return res.id
}
const post = () => {
  if (props.auction.id === -1) {
    registAuction()
  } else {
    updateAuction()
  }
  emit('close')
}
</script>

<template>
  <v-card min-width="300">
    <v-card-title>市場開催日登録</v-card-title>
    <v-card-text>
      <v-text-field variant="outlined" type="date" v-model="auctionDate" label="開催日"></v-text-field>
      <v-text-field variant="outlined" v-model="name" label="市場の名称"></v-text-field>
      <v-text-field v-model="lisenceNumber" label="古物市場主許可番号" variant="outlined" hint="○○県xxxxxxxxxx号"></v-text-field>
      <v-row>
        <v-col>
          <v-text-field variant="outlined" label="売分" suffix="%" type="number" v-model="sellingMarginRate"></v-text-field>
        </v-col>
        <v-col>
          <v-text-field variant="outlined" label="買分" suffix="%" type="number" v-model="buyingMarginRate"></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-btn variant="outlined" prepend-icon="mdi-storefront-plus" size="x-large" color="primary" block @click="post">登録</v-btn>
    </v-card-actions>
  </v-card>
</template>

import { createStore } from 'vuex'
import axios from 'axios'

const appName = '古物市場取引システム'
const handleApiError = (error) => {
  console.log('############################')
  console.log(error)
  console.log('############################')
  throw new Error('APIエラーが発生しました')
}

const store = createStore({
  state: {
    isLogin: false,
    userId: '',
    auctionId: '',
    auctionTitle: appName,
    cookie: '',
    isAdmin: false,
    appName: appName,
    configure: {
      defaultAuctionName: '',
      defaultLisenceNumber: '',
      defaultSellingMarginRate: 0,
      defaultBuyingMarginRate: 0,
      useAuctioneerNumber: false,
      useAutoItemLabel: false
    }
  },
  getters: {
  },
  mutations: {
    auth (state, user) {
      state.isLogin = true
      state.userId = user.userId
      state.isAdmin = user.isAdmin
    },
    logoff (state) {
      state.isLogin = false
      state.userId = ''
      state.auctionTitle = appName
      state.auctionId = ''
      state.isAdmin = false
    },
    auction (state, auction) {
      state.auctionId = auction.id
      state.auctionTitle = auction.name
    },
    setCookie (state, value) {
      state.cookie = value
    },
    configure ({ configure }, value) {
      configure.defaultAuctionName = value.default_auction_name
      configure.defaultLisenceNumber = value.default_auction_lisence_number
      configure.defaultSellingMarginRate = value.default_selling_margin_rate
      configure.defaultBuyingMarginRate = value.default_buying_margin_rate
      configure.useAuctioneerNumber = value.use_auctioneer_number
      configure.useAutoItemLabel = value.use_auto_item_label
    }
  },
  actions: {
    login (context, user) {
      context.commit('auth', user)
      context.dispatch('reloadConfigure')
    },
    logoff ({ commit }) {
      commit('logoff')
    },
    async setAuction ({ commit }, auctionId) {
      if (auctionId === '') {
        commit({ id: '', name: appName })
        return ''
      }
      const res = await axios.get(`https://api.minfuu.jp/auctions/${auctionId}`, { withCredentials: true })
        .then((response) => response.data)
        .catch((error) => {
          handleApiError(error)
        })
      commit('auction', { id: auctionId, name: `${res.auction_date} ${res.name}` })
      return ''
    },
    setCookie ({ commit }, value) {
      commit('setCookie', value)
    },
    async reloadConfigure ({ commit }) {
      const res = await axios.get('https://api.minfuu.jp/configure', { withCredentials: true })
        .then((response) => {
          return response.data
        }).catch((error) => {
          handleApiError(error)
        })
      commit('configure', res)
    }
  },
  modules: {
  }
})
export default store

<script setup>
import { ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import axios from 'axios'

const authId = ref('')
const authPass = ref('')
const showPassword = ref(false)
const store = useStore()
const router = useRouter()

const post = async () => {
  const formdata = new FormData()
  formdata.append('id', authId.value)
  formdata.append('password', authPass.value)
  const res = await axios.post('https://api.minfuu.jp/login', formdata, { maxRedirects: 0, withCredentials: true })
    .then((response) => {
      store.dispatch('setCookie', response.headers['set-cookie'])
      return response.data
    }).catch((error) => {
      console.log(error)
      return 'failed'
    })
  if (res !== 'failed') {
    store.dispatch('login', { userId: authId.value, isAdmin: res.is_admin })
    router.push('/auctions')
  } else {
    console.log('login fail')
  }
}
</script>

<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" md="6">
        <v-form @submit.prevent="post">
          <v-card max-width="600">
          <v-card-title>ログイン</v-card-title>
          <v-card-text>
            <v-text-field prepend-icon="mdi-account-circle" label="ユーザーID" v-model="authId"></v-text-field>
            <v-text-field prepend-icon="mdi-lock" label="パスワード" v-model="authPass" :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" :type="showPassword ? 'text' : 'password'" @click:append="showPassword = !showPassword"></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-btn variant="elevated" prepend-icon="mdi-login" size="x-large" color="primary" block type="submit">ログイン</v-btn>
          </v-card-actions>
        </v-card>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

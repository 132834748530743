<script setup>
import { ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import axios from 'axios'

import RegistAuctionForm from './forms/RegistAuctionsForm.vue'

const apiDomain = 'https://api.minfuu.jp'
const store = useStore()
const router = useRouter()
const auctions = ref([])
const selectedAuctionsId = ref('')
const isLoading = ref(false)
const showDialog = ref(false)
const editingAuction = ref(null)

const handleApiError = (error) => {
  console.log('############################')
  console.log(error)
  console.log('############################')
  router.push('/login')
  // throw new Error('APIエラーが発生しました')
}

const updateAllAuctionList = async () => {
  isLoading.value = true
  const res = await axios.get(`${apiDomain}/auctions`, { withCredentials: true })
    .then((response) => {
      isLoading.value = false
      return response.data
    }).catch((error) => {
      isLoading.value = false
      handleApiError(error)
    })
  auctions.value = res
  auctions.value.forEach(x => { x.title = `${x.auction_date} ${x.name}－${x.item_count}取引` })
  selectedAuctionsId.value = auctions.value[0].id
}
const storeAuction = () => {
  editingAuction.value = {
    id: -1,
    auctionDate: '',
    name: store.state.configure.defaultAuctionName,
    lisenceNumber: store.state.configure.defaultLisenceNumber,
    sellingMarginRate: store.state.configure.defaultSellingMarginRate,
    buyingMarginRate: store.state.configure.defaultBuyingMarginRate
  }
  showDialog.value = true
}
const editAuction = async () => {
  const res = await axios.get(`${apiDomain}/auctions/${selectedAuctionsId.value}`, { withCredentials: true })
    .then((response) => response.data)
    .catch((error) => {
      handleApiError(error)
    })
  editingAuction.value = {
    id: res.id,
    auctionDate: res.auction_date,
    name: res.name,
    sellingMarginRate: res.selling_margin_rate,
    buyingMarginRate: res.buying_margin_rate,
    lisenceNumber: res.lisence_number
  }
  showDialog.value = true
}

const selectAuction = async () => {
  await store.dispatch('setAuction', selectedAuctionsId.value)
  router.push('/participants')
}

const closeDialog = () => {
  updateAllAuctionList()
  showDialog.value = false
}
const deleteAuction = async () => {
  if (window.confirm('本当に削除しますか？')) {
    await axios.delete(`${apiDomain}/auctions/${selectedAuctionsId.value}`, { withCredentials: true })
      .then((response) => response.data)
      .catch((error) => {
        handleApiError(error)
      })
    updateAllAuctionList()
    selectedAuctionsId.value = auctions.value[0].id
  }
}
updateAllAuctionList()
</script>

<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" lg="8">
        <v-form>
          <v-card>
            <v-card-title>市場を選択</v-card-title>
            <v-card-text>
              <v-select variant="outlined" v-model="selectedAuctionsId" label="登録済み市場" :items="auctions" item-title="title" item-value="id">
                <template #append>
                  <v-progress-circular v-if="isLoading" color="primary" size="24"></v-progress-circular>
                </template>
              </v-select>
            </v-card-text>
            <v-card-actions>
              <v-row justify="center">
                <v-col cols="12" md="6">
                  <v-btn variant="elevated" prepend-icon="mdi-storefront-check" size="x-large" color="primary" block @click="selectAuction" :disabled="isLoading">選択</v-btn>
                </v-col>
                <v-col cols="12" md="6">
                  <v-btn variant="outlined" prepend-icon="mdi-storefront-plus" size="x-large" color="primary" @click="storeAuction">新規</v-btn>
                  <v-btn variant="elevated" prepend-icon="mdi-storefront-edit" size="x-large" color="info" @click="editAuction" :disabled="isLoading">編集</v-btn>
                  <v-btn variant="elevated" prepend-icon="mdi-storefront-remove" size="x-large" color="warning" @click="deleteAuction" :disabled="isLoading">削除</v-btn>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-col>
    </v-row>
    <v-dialog width="auto" v-model="showDialog">
      <RegistAuctionForm :auction="editingAuction" @close="closeDialog"></RegistAuctionForm>
    </v-dialog>
  </v-container>
</template>

<script setup>
import { ref, defineProps, defineEmits } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import axios from 'axios'

const apiDomain = 'https://api.minfuu.jp'

const store = useStore()
const router = useRouter()
const emit = defineEmits(['close'])
const props = defineProps({
  auctionItem: Object
})
const sellerParticipants = ref([])
const buyerParticipants = ref([])

const id = ref(props.auctionItem.id)
const number = ref(props.auctionItem.number)
const sellerId = ref(props.auctionItem.sellerId)
const buyerId = ref(props.auctionItem.buyerId)
const category = ref(props.auctionItem.category)
const author = ref(props.auctionItem.author)
const title = ref(props.auctionItem.title)
const price = ref(props.auctionItem.price)

const handleApiError = (error) => {
  console.log('############################')
  console.log(error)
  console.log('############################')
  router.push('/login')
  // throw new Error('APIエラーが発生しました')
}

const updateAuctionItem = async () => {
  const data = {
    id: id.value,
    number: number.value,
    seller_id: sellerId.value,
    buyer_id: buyerId.value,
    category: category.value,
    author: author.value,
    title: title.value,
    price: price.value
  }
  await axios.put(`${apiDomain}/transactions/${id.value}`, { item: data }, { withCredentials: true })
    .then((response) => response.data)
    .catch((error) => {
      handleApiError(error)
    })
  emit('close')
}
const updateParticipantsList = async () => {
  const res = await axios.get(`${apiDomain}/auctions/${store.state.auctionId}/participants`, { params: { only_participants: true }, withCredentials: true })
    .then((response) => response.data)
    .catch((error) => {
      handleApiError(error)
    })
  sellerParticipants.value = res
  buyerParticipants.value = res
}
updateParticipantsList()
</script>

<template>
  <v-card min-width="400">
    <v-card-title>出品情報</v-card-title>
    <v-card-text>
      <v-text-field v-model="number" label="番号" variant="outlined"></v-text-field>
      <v-select variant="outlined" v-model="sellerId" label="出品者" :items="sellerParticipants" item-title="name" item-value="id">
      </v-select>
      <v-text-field v-model="category" label="種類" variant="outlined"></v-text-field>
      <v-text-field v-model="author" label="概要" variant="outlined"></v-text-field>
      <v-text-field v-model="title" label="明細" variant="outlined"></v-text-field>
      <v-select variant="outlined" v-model="buyerId" label="購入者" :items="buyerParticipants" item-title="name" item-value="id">
      </v-select>
      <v-text-field v-model="price" inputmode="numeric" type="number" label="金額" variant="outlined"></v-text-field>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn variant="outlined" prepend-icon="mdi-receipt-text-check" size="x-large" color="primary" block @click="updateAuctionItem">登録</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script setup>
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import axios from 'axios'
import { VDataTable } from 'vuetify/labs/VDataTable'

import RegistAuctionItemForm from './forms/RegistAuctionItemForm.vue'
import SelectAuctioneersBox from './parts/SelectAuctioneersBox.vue'

const apiDomain = 'https://api.minfuu.jp'
let maxNumber = 0
const store = useStore()
const router = useRouter()
const auctionId = store.state.auctionId
const sellerId = ref('')
const buyerId = ref('')
const category = ref('')
const author = ref('')
const title = ref('')
const price = ref(0)
const showDialog = ref(false) // ダイアログ表示
const isLoading = ref(false)
const participants = ref([])
const items = ref([])
const edittingItem = ref(null)
const numberRures = [
  v => /[0-9]+/.test(v) || '数字以外が入力されています'
]
const handleApiError = (error) => {
  console.log('############################')
  console.log(error)
  console.log('############################')
  router.push('/login')
  // throw new Error('APIエラーが発生しました')
}
const updateParticipantsList = async () => {
  const res = await axios.get(`${apiDomain}/auctions/${auctionId}/participants`, { params: { only_participants: true }, withCredentials: true })
    .then((response) => response.data)
    .catch((error) => {
      handleApiError(error)
    })
  participants.value = res
  sellerId.value = participants.value[0].id
  buyerId.value = participants.value[0].id
}
const updateAuctionItemList = async () => {
  isLoading.value = true
  const res = await axios.get(`${apiDomain}/auctions/${auctionId}/transactions`, { withCredentials: true })
    .then((response) => {
      isLoading.value = false
      return response.data
    }).catch((error) => {
      isLoading.value = false
      handleApiError(error)
    })
  items.value = res
  items.value.forEach(x => {
    x.seller_title = `${x.seller_number.toString()} ${x.seller_name}`
    x.buyer_title = `${x.buyer_number.toString()} ${x.buyer_name}`
  })
  maxNumber = items.value[0].number
}
const registItem = async () => {
  const data = {
    number: maxNumber + 1,
    seller_id: sellerId.value,
    category: category.value,
    author: author.value,
    title: title.value,
    buyer_id: buyerId.value,
    price: price.value,
    auctions_id: auctionId
  }
  const res = await axios.post(`${apiDomain}/transactions`, { item: data }, { withCredentials: true })
    .then((response) => response.data)
    .catch((error) => {
      handleApiError(error)
    })
  if (store.state.configure.useAutoItemLabel === 1) {
    window.open(`${apiDomain}/report/items/${store.state.auctionId}/${res.id}`)
  }
  author.value = ''
  title.value = ''
  price.value = 0
  updateAuctionItemList()
}
const deleteAuctionItem = async (id) => {
  await axios.delete(`${apiDomain}/transactions/${id}`, { withCredentials: true })
    .then((response) => response.data)
    .catch((error) => {
      handleApiError(error)
    })
}
const itemsHeaders = [
  { title: '番号', key: 'number', align: 'end' },
  { title: '出品者', key: 'seller_title', align: 'start' },
  { title: '種類', key: 'category' },
  { title: '品名', key: 'title' },
  { title: '備考', key: 'author' },
  { title: '購入者', key: 'buyer_title', align: 'start' },
  { title: '価格', key: 'price', align: 'end' },
  { title: '操作', key: 'actions', sortable: false }
]
const participantsHeaders = [
  { title: '番号', key: 'number', align: 'end' },
  { title: '正式名称', align: 'start', sortable: false, key: 'name' },
  { title: '表示名称', align: 'start', sortable: false, key: 'view_name' },
  { title: 'よみがな', align: 'begin', key: 'reading' },
  { title: 'インボイス', key: 'tax_code' }
]
const editItem = async (item) => {
  const res = await axios.get(`${apiDomain}/items/${item.id}`, { withCredentials: true })
    .then((response) => response.data)
    .catch((error) => {
      handleApiError(error)
    })
  edittingItem.value = {
    id: item.id,
    number: item.number,
    sellerId: res.seller_id,
    buyerId: res.buyer_id,
    category: res.category,
    author: res.author,
    title: res.title,
    price: res.price
  }
  showDialog.value = true
}
const deleteItem = (item) => {
  if (window.confirm('本当に削除しますか？')) {
    deleteAuctionItem(item.id)
    updateAuctionItemList()
  }
}
const closeDialog = () => {
  showDialog.value = false
  updateAuctionItemList()
}
const focused = (focusEvent) => {
  focusEvent.target.select()
}
const convertNumber = () => {
  if (isNaN(price.value)) {
    price.value = price.value.replace(/[０-９]/g, (s) => String.fromCharCode(s.charCodeAt(0) - 0xFEE0))
  } else if (price.value === '') {
    price.value = 0
  }
}
updateParticipantsList()
updateAuctionItemList()
const tab = ref()
const configure = computed(() => store.state.configure)
</script>

<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="5">
        <v-card class="pa-5">
          <v-card-title>出品受付</v-card-title>
          <v-card-text>
            <SelectAuctioneersBox v-model="sellerId" label="出品者" :auctioneers="participants" :numberInput="configure.useAuctioneerNumber"></SelectAuctioneersBox>
            <v-text-field v-model="category" label="種類" variant="outlined" clearable></v-text-field>
            <v-text-field v-model="title" label="品名" variant="outlined"></v-text-field>
            <v-text-field v-model="author" label="備考(作者など)" variant="outlined"></v-text-field>
            <SelectAuctioneersBox v-model="buyerId" label="購入者" :auctioneers="participants" :numberInput="configure.useAuctioneerNumber"></SelectAuctioneersBox>
            <v-text-field v-model="price" label="金額" variant="outlined" inputmode="numeric" type="number" @focus="focused" @blur="convertNumber" :rules="numberRures"></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-btn variant="elevated" prepend-icon="mdi-check-circle-outline" size="x-large" color="primary" block @click="registItem">登録</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" md="7">
        <v-card>
          <v-tabs v-model="tab">
            <v-tab value="items">本日の取引</v-tab>
            <v-tab value="participants">参加者一覧</v-tab>
          </v-tabs>
          <v-card-text max-height="600">
            <v-window v-model="tab">
              <v-window-item value="items">
                <v-data-table class="elevation-1" items-per-page="-1" :headers="itemsHeaders" :items="items" item-value="id" disable-pagination hide-default-footer fixed-header>
                  <template v-slot:top>
                    <v-dialog width="auto" v-model="showDialog">
                      <RegistAuctionItemForm :auctionItem="edittingItem" @close="closeDialog"></RegistAuctionItemForm>
                    </v-dialog>
                  </template>
                  <template #[`item.price`]="{ item }">
                    {{ item.columns.price.toLocaleString() }}
                  </template>
                  <template #[`item.actions`]="{ item }">
                    <v-icon size="small" class="me-2" @click="editItem(item.raw)">mdi-pencil</v-icon>
                    <v-icon size="small" @click="deleteItem(item.raw)">mdi-delete</v-icon>
                  </template>
                </v-data-table>
              </v-window-item>
              <v-window-item value="participants">
                <v-data-table class="elevation-1" items-per-page="-1" :headers="participantsHeaders" :items="participants" item-value="id" disable-pagination hide-default-footer fixed-header>
                </v-data-table>
              </v-window-item>
            </v-window>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
  <v-dialog v-model="isLoading" persistent max-width="300" class="spinner-dialog">
    <v-card>
      <v-card-text>
        <v-row justify="center">
          <v-progress-circular color="primary" indeterminate size="64"></v-progress-circular>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<style scoped>
.spinner-dialog.v-dialog .v-card {
  overflow: hidden !important;
  background-color: transparent !important;
  box-shadow: none !important;
}
.spinner-dialog.v-dialog {
  background-color: rgba(0, 0, 0, 0.5) !important;
}
</style>

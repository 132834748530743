<script setup>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import axios from 'axios'

const router = useRouter()
const name = ref('')
const mail = ref('')
const message = ref('')
const canSubmit = ref(true)
const formField = ref(null)

const handleApiError = (error) => {
  console.log('############################')
  console.log(error)
  console.log('############################')
  router.push('/login')
  // throw new Error('APIエラーが発生しました')
}
const post = async () => {
  const validResult = await formField.value.validate()
  if (!validResult.valid) {
    console.log('fail')
    return
  }
  const data = {
    subject: '古物市場システムより問い合わせ',
    to_address: 'support@minfuu.jp',
    messsage: `お名前： ${name.value}\nメール： ${mail.value}\n内容：\n${message.value}`
  }
  await axios.post('https://api.minfuu.jp/sm', data, { withCredentials: true })
    .then((response) => response.data)
    .catch((error) => {
      handleApiError(error)
    })
}
const rules = {
  required: [
    v => !!v || '必須項目です'
  ],
  email: [
    v => /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(v) || '有効なメールアドレスを入力してください'
  ]
}
</script>

<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" md="6">
        <v-card>
          <v-form @submit.prevent="post" ref="formField">
            <v-card-title>お問い合わせ</v-card-title>
            <v-card-text>
              <v-text-field label="お客様名" v-model="name" :rules="rules.required" :disabled="!canSubmit"></v-text-field>
              <v-text-field label="メールアドレス" v-model="mail" type="email" :rules="rules.email || rules.required" :disabled="!canSubmit"></v-text-field>
              <v-textarea label="本文" v-model="message" :rules="rules.required" :disabled="!canSubmit"></v-textarea>
              <span v-if="!canSubmit" block>送信を完了しました</span>
            </v-card-text>
            <v-card-actions>
              <v-btn variant="elevated" color="primary" prepend-icon="mdi-email-fast" size="x-large" block type="submit" :disabled="!canSubmit">送信</v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
import { ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import axios from 'axios'

const apiDomain = 'https://api.minfuu.jp'

const store = useStore()
const router = useRouter()

const name = ref('')
const postal = ref('')
const address = ref('')
const address2 = ref('')
const phone = ref('')
const email = ref('')
const taxCode = ref('')
const userName = ref('')
const newPass = ref('')
const authPass = ref('')

const showNewPassword = ref(false)
const showAuthPassword = ref(false)
const canSubmit = ref(true)

const formField = ref(null)
const jsonpAdapter = require('axios-jsonp')

const rules = {
  require: [
    v => !!v || '必須項目です'
  ],
  pass: [
    v => v === newPass.value || 'パスワードが異なっています'
  ],
  email: [
    v => /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(v) || '有効なメールアドレスを入力してください'
  ],
  postal: [
    v => /^[0-9]{3}-?[0-9]{4}$/.test(v) || '郵便番号形式(ハイフンは任意)で入力してください'
  ]
}
const handleApiError = (error) => {
  console.log('############################')
  console.log(error)
  console.log('############################')
  router.push('/login')
  // throw new Error('APIエラーが発生しました')
}

const searchAddress = () => {
  axios.get(`https://api.zipaddress.net/?zipcode=${postal.value}`, { adapter: jsonpAdapter })
    .then(res => {
      if (res.data.code === 404) {
        return
      }
      address.value = res.data.fullAddress
    })
}
const storeUser = async () => {
  const validResult = await formField.value.validate()
  if (!validResult.valid) {
    console.log('fail')
    return
  }
  const clientData = {
    name: name.value,
    postal_code: postal.value,
    address: address.value,
    address2: address2.value,
    phone: phone.value,
    email: email.value,
    tax_code: taxCode.value
  }
  const res = await axios.post(`${apiDomain}/clients`, clientData, { withCredentials: true })
    .then((response) => response.data)
    .catch((error) => {
      handleApiError(error)
    })
  const userData = {
    user_id: userName.value,
    password: newPass.value,
    clients_id: res.id
  }
  const userRes = await axios.post(`${apiDomain}/users`, userData, { withCredentials: true })
    .then((response) => response.data)
    .catch((error) => {
      handleApiError(error)
    })
  let message = `${name.value}様\n\nこの度は${store.state.appName}に仮登録いただき、誠にありがとうございます。\n下記リンクからアクセスいただきますと、本登録となります。`
  message += `https://api.minfuu.jp/users/activate?user_name=${userName.value}&activation_code=${userRes.activation_code}\n\n`
  message += 'よろしくお願いいたします。\n合同会社 みんふう楽器店\nシステムサポート部\nTEL:050-5436-3421\nE-mail:support@minfuu.jp'
  const mailData = {
    subject: `[仮登録完了]${store.state.appName}にご仮登録ありがとうございます`,
    to_address: email.value,
    message: message,
    from_address: 'support@minfuu.jp'
  }
  await axios.post(`${apiDomain}/sm`, mailData, { withCredentials: true })
    .then((response) => {
      return response.data
    }).catch((error) => {
      handleApiError(error)
    })
  canSubmit.value = false
  return userRes.id
}
</script>

<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" md="6">
        <v-form @submit.prevent="storeUser" ref="formField">
          <v-card class="px-5 pt-7">
            <v-card-title><h2>市場の主催者情報</h2></v-card-title>
            <v-card-subtitle>明細書等に載る情報ですので、正確にご入力ください</v-card-subtitle>
            <v-card-text>
              <v-text-field variant="outlined" v-model="name" label="名称／屋号" hint="必須項目" :rules="rules.require"></v-text-field>
              <v-text-field variant="outlined" v-model="postal" label="郵便番号" hint="必須項目" @blur="searchAddress" :rules="rules.postal"></v-text-field>
              <v-text-field variant="outlined" v-model="address" label="住所" hint="必須項目" :rules="rules.require"></v-text-field>
              <v-text-field variant="outlined" v-model="address2" label="住所２行目"></v-text-field>
              <v-text-field variant="outlined" v-model="phone" label="電話番号"></v-text-field>
              <v-text-field variant="outlined" v-model="email" label="メール" hint="必須項目" :rules="rules.email"></v-text-field>
              <v-text-field variant="outlined" v-model="taxCode" label="インボイス確認番号"></v-text-field>
            </v-card-text>
          </v-card>
          <v-card class="px-5 pt-7">
            <v-card-title><h2>ログイン情報</h2></v-card-title>
            <v-card-subtitle></v-card-subtitle>
            <v-card-text>
              <v-text-field variant="outlined" prepend-icon="mdi-account-circle" label="ユーザーID" v-model="userName" hint="必須項目" :rules="rules.require"></v-text-field>
              <v-text-field variant="outlined" prepend-icon="mdi-lock" label="パスワード" v-model="newPass" :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'" :type="showNewPassword ? 'text' : 'password'" @click:append="showNewPassword = !showNewPassword"></v-text-field>
              <v-text-field variant="outlined" prepend-icon="mdi-lock" label="パスワード(確認)" v-model="authPass" :rules="rules.pass" :append-icon="showAuthPassword ? 'mdi-eye' : 'mdi-eye-off'" :type="showAuthPassword ? 'text' : 'password'" @click:append="showAuthPassword = !showAuthPassword"></v-text-field>
            </v-card-text>
            <v-card-actions>
              <v-btn prepend-icon="mdi-office-building-plus" variant="elevated" color="primary" size="x-large" block type="submit" :disabled="!canSubmit">登録</v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
import { ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import axios from 'axios'

const store = useStore()
const router = useRouter()
const auctionName = ref('')
const lisenceNumber = ref('')
const sellingMarginRate = ref('')
const buyingMarginRate = ref('')
const useAuctioneerNumber = ref(0)
const useAutoItemLabel = ref(0)

const handleApiError = (error) => {
  console.log('############################')
  console.log(error)
  console.log('############################')
  router.push('/login')
  // throw new Error('APIエラーが発生しました')
}
const loadSetting = async () => {
  const res = await axios.get('https://api.minfuu.jp/configure', { withCredentials: true })
    .then((response) => response.data)
    .catch((error) => {
      handleApiError(error)
    })
  auctionName.value = res.default_auction_name
  lisenceNumber.value = res.default_auction_lisence_number
  sellingMarginRate.value = res.default_selling_margin_rate
  buyingMarginRate.value = res.default_buying_margin_rate
  useAuctioneerNumber.value = res.use_auctioneer_number
  useAutoItemLabel.value = res.use_auto_item_label
}
const updateSetting = async () => {
  const data = {
    clients_id: store.state.clientsId,
    default_auction_name: auctionName.value,
    default_auction_lisence_number: lisenceNumber.value,
    default_selling_margin_rate: sellingMarginRate.value,
    default_buying_margin_rate: buyingMarginRate.value,
    use_auctioneer_number: useAuctioneerNumber.value,
    use_auto_item_label: useAutoItemLabel.value
  }
  const res = await axios.put('https://api.minfuu.jp/configure', data, { withCredentials: true })
    .then((response) => response.data)
    .catch((error) => {
      handleApiError(error)
    })
  store.dispatch('reloadConfigure')
  return res.id
}
loadSetting()
</script>

<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="6">
        <v-card>
          <v-card-title><h2>初期値設定</h2></v-card-title>
          <v-card-text>
            <v-text-field variant="outlined" v-model="auctionName" label="市場の名称"></v-text-field>
            <v-text-field variant="outlined" v-model="lisenceNumber" label="古物市場主許可番号" hint="○○県xxxxxxxxxx号"></v-text-field>
            <v-row>
              <v-col>
                <v-text-field variant="outlined" label="売分" suffix="%" type="number" v-model="sellingMarginRate"></v-text-field>
              </v-col>
              <v-col>
                <v-text-field variant="outlined" label="買分" suffix="%" type="number" v-model="buyingMarginRate"></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn variant="outlined" prepend-icon="mdi-storefront-plus" size="x-large" color="primary" block @click="updateSetting">登録</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card>
          <v-card-title><h2>機能設定</h2></v-card-title>
          <v-card-text>
            <v-radio-group label="取引入力時に顧客番号を使用" v-model="useAuctioneerNumber" inline>
              <v-radio label="する" :value="1"></v-radio>
              <v-radio label="しない" :value="0"></v-radio>
            </v-radio-group>
            <v-radio-group label="取引入力完了時に品札をPDF表示" v-model="useAutoItemLabel" inline>
              <v-radio label="する" :value="1"></v-radio>
              <v-radio label="しない" :value="0"></v-radio>
            </v-radio-group>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn variant="outlined" prepend-icon="mdi-storefront-plus" size="x-large" color="primary" block @click="updateSetting">登録</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

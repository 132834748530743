<script setup>
import { ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import axios from 'axios'

const apiDomain = 'https://api.minfuu.jp'
const router = useRouter()
const store = useStore()
const participantId = ref('')
const participants = ref([])
const isLoading = ref(false)
const detailsType = ref('each')

const handleApiError = (error) => {
  console.log('############################')
  console.log(error)
  console.log('############################')
  router.push('/login')
  // throw new Error('APIエラーが発生しました')
}
const findAllAuctioneers = async () => {
  isLoading.value = true
  const res = await axios.post(`${apiDomain}/transaction/auctioneers`, { auctions_id: store.state.auctionId }, { withCredentials: true })
    .then((response) => response.data)
    .catch((error) => {
      handleApiError(error)
    })
  participants.value = res
  participantId.value = participants.value[0].id
  isLoading.value = false
}
const printDetails = async () => {
  window.open(`${apiDomain}/report/detail/${store.state.auctionId}` + (detailsType.value === 'each' ? `/${participantId.value}` : ''))
}
const printBilling = async () => {
  window.open(`${apiDomain}/report/billing/${store.state.auctionId}`)
}
const printSales = async () => {
  window.open(`${apiDomain}/report/transaction/${store.state.auctionId}`)
}
findAllAuctioneers()
</script>

<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" md="4">
        <v-card>
          <v-card-title>取引明細印刷</v-card-title>
          <v-card-text>
            <v-radio-group v-model="detailsType">
              <v-radio value="each" label="個別ダウンロード"></v-radio>
              <v-select variant="outlined" v-model="participantId" label="参加者" :items="participants" item-title="name" item-value="id" :disabled="detailsType==='batch'">
                <template #append>
                  <v-progress-circular v-if="isLoading" color="primary" size="24"></v-progress-circular>
                </template>
              </v-select>
              <v-radio value="batch" label="一括ダウンロード"></v-radio>
            </v-radio-group>
          </v-card-text>
          <v-card-actions>
            <v-btn variant="elevated" prepend-icon="mdi-file-document-outline" block size="x-large" color="info" @click="printDetails" :disabled="isLoading">PDF表示</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" md="4">
        <v-card>
          <v-card-title>受払一覧表</v-card-title>
          <v-card-text>
          </v-card-text>
          <v-card-actions>
            <v-btn variant="elevated" prepend-icon="mdi-file-document-outline" block size="x-large" color="info" @click="printBilling" :disabled="isLoading">PDF表示</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" md="4">
        <v-card>
          <v-card-title>取引一覧表</v-card-title>
          <v-card-text>
          </v-card-text>
          <v-card-actions>
            <v-btn variant="elevated" prepend-icon="mdi-file-document-outline" block size="x-large" color="info" @click="printSales" :disabled="isLoading">PDF表示</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

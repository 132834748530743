<script setup>
import { ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import RegistUserForm from '../components/forms/RegistUsersForm.vue'

const store = useStore()
const router = useRouter()
const showDrawer = ref(false)
const showRegistUserDialog = ref(false)
const sidebarItems = [
  {
    title: 'オークション選択',
    path: '/auctions',
    icon: ''
  }
]
const mainItems = [
  {
    categoryName: 'ユーザー',
    contents: [
      {
        title: 'ユーザー登録',
        description: '新規ユーザーを登録します'
      }
    ]
  }
]
const changePassword = () => {
  router.push('/change-pass')
}
const logout = () => {
  store.dispatch('logoff')
}
</script>

<template>
  <v-app>
    <v-app-bar app dark class="px-md-6">
      <v-app-bar-nav-icon @click="showDrawer=!showDrawer"></v-app-bar-nav-icon>
      <v-app-bar-title>
        <span>{{ store.state.auctionTitle }}</span>
      </v-app-bar-title>
      <h3>user:{{ store.state.userId }}</h3>
    </v-app-bar>
    <v-navigation-drawer v-model="showDrawer" temporary>
      <v-list>
        <v-list-item v-for="item in sidebarItems" :key="item.to" :to="item.path">
          <v-icon :icon="item.icon" class="mr-6" />
          <v-list-item-title class="font-weight-bold" v-text="item.title" />
        </v-list-item>
      </v-list>
      <template v-slot:append>
        <v-btn block @click="changePassword">パスワード変更</v-btn>
        <v-btn block @click="logout">ログアウト</v-btn>
      </template>
    </v-navigation-drawer>
    <v-main>
      <v-container>
        <v-row>
          <template v-for="row in mainItems" :key="row.categoryName">
            <v-col class="mt-2" cols="12">
              <strong>{{ categoryName}}</strong>
            </v-col>
            <v-col v-for="content in row.contents" :key="`${row.categoryName}${content.to}`" cols="6" md="2">
              <v-card>
                <v-card-title>{{ content.title }}</v-card-title>
                <v-card-subtitle>{{ content.description }}</v-card-subtitle>
                <v-card-text>
                  <v-dialog width="auto" v-model="showRegistUserDialog">
                    <RegistUserForm @close="showRegistUserDialog=false"></RegistUserForm>
                  </v-dialog>
                </v-card-text>
                <v-card-actions>
                  <v-btn variant="outlined" @click="showRegistUserDialog=true">ダイアログを開く</v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </template>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

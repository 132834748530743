import { createRouter, createWebHistory } from 'vue-router'
import Store from '../store/index.js'

import LoginView from '../views/LoginView.vue'
import MainView from '../views/MainView.vue'
import AdminView from '../views/AdminView.vue'

import SelectAuctions from '../components/SelectAuctions.vue'
import SelectParticipants from '../components/SelectParticipants.vue'
import AuctionItems from '../components/AuctionItems.vue'
import ReportMenu from '../components/ReportMenu.vue'
import ChangePass from '../components/ChangePass.vue'
import ContactUs from '../components/ContactUs.vue'
import LoggingIn from '../components/LoggingIn.vue'
import LandingContent from '../components/LandingContent.vue'
import RegistClient from '../components/RegistClient.vue'
import RegistPayment from '../components/RegistPayment.vue'
import ClientConfiguration from '../components/ClientConfiguration.vue'

const routes = [
  {
    path: '/loginView',
    name: 'Home',
    component: LoginView,
    children: [
      {
        path: '/',
        name: 'LandingContent',
        component: LandingContent
      },
      {
        path: '/login/contact',
        name: 'LoginContact',
        component: ContactUs
      },
      {
        path: '/regist',
        name: 'RegistClient',
        component: RegistClient
      },
      {
        path: '/payment',
        name: 'RegistPayment',
        component: RegistPayment
      },
      {
        path: '/login',
        name: 'Login',
        component: LoggingIn
      }
    ]
  },
  {
    path: '/admin',
    name: 'Admin',
    component: AdminView
  },
  {
    path: '/main',
    component: MainView,
    meta: {
      requiesAuth: true
    },
    children: [
      {
        path: '/configure',
        name: 'Configuration',
        component: ClientConfiguration
      },
      {
        path: '/contact',
        name: 'Contact',
        component: ContactUs
      },
      {
        path: '/change-pass',
        name: 'ChangePassword',
        component: ChangePass
      },
      {
        path: '/participants',
        name: 'SelectParticipants',
        component: SelectParticipants
      },
      {
        path: '/auctionitems',
        name: 'AuctionItems',
        component: AuctionItems
      },
      {
        path: '/reports',
        name: 'ReportMenu',
        component: ReportMenu
      },
      {
        path: '/auctions',
        name: 'Auctions',
        component: SelectAuctions
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiesAuth)) {
    if (!Store.state.isLogin) {
      next({
        path: '/login',
        query: {
          redirect: to.fullPath
        }
      })
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router

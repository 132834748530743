<script setup>
import { ref, watch } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'

const store = useStore()
const route = useRoute()

const breadcrumbsItems = ref([
  {
    name: 'Auctions',
    title: '市場選択',
    to: '/auctions',
    disabled: true
  },
  {
    name: 'SelectParticipants',
    title: '参加者一覧',
    to: '/participants',
    disabled: false
  },
  {
    name: 'AuctionItems',
    title: '取引',
    to: '/auctionitems',
    disabled: false
  },
  {
    name: 'ReportMenu',
    title: '帳票印刷',
    to: '/reports',
    disabled: false
  }
])
watch(() => route.name, (name) => {
  breadcrumbsItems.value.forEach(x => {
    x.disabled = (x.name === name)
  })
})
</script>

<template>
  <v-breadcrumbs v-if="store.state.auctionId !== ''" :items="breadcrumbsItems" divider="-"></v-breadcrumbs>
</template>

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import '@mdi/font/css/materialdesignicons.css'

const theme = {
  dark: false,
  colors: {
    primary: '#6D4C41', // レトロブラウン
    secondary: '#FFD700', // ゴールデンイエロー
    accent: '#8FBC8F', // クラシックグリーン
    error: '#B22222', // ビンテージブルー
    info: '#FFC0CB', // オールドピンク
    success: '#228B22', // 成功時の色
    warning: '#FFA500' // 警告時の色
  },
  typography: {
    fontFamily: 'Roboto, sans-serif',
    fontSizes: {
      xs: 12,
      sm: 14,
      md: 16,
      lg: 18,
      xl: 20
    }
  },
  background: {
    base: '#F5F5F5',
    secondary: '#ECECEC'
  },
  border: {
    color: '#D3D3D3',
    radius: 5
  },
  shadows: {
    default: '0px 0px 5px 0px rgba(0, 0, 0, 0.5)',
    sm: '0px 0px 3px 0px rgba(0, 0, 0, 0.2)',
    lg: '0px 0px 10px 0px rgba(0, 0, 0, 0.7)'
  },
  icons: {
    iconfont: 'mdi'
  }
}

const vuetify = createVuetify({
  components,
  directives,
  theme: {
    defaultTheme: 'theme',
    themes: {
      theme
    }
  }
})

createApp(App).use(router).use(vuetify).use(store).mount('#app')

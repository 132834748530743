<script setup>
import { ref } from 'vue'

const sidebarLinks = [
  {
    title: 'システムの紹介',
    to: '/',
    icon: 'mdi-home'
  },
  {
    title: '無料会員登録',
    to: '/regist',
    icon: 'mdi-office-building-plus'
  },
  {
    title: 'ログイン',
    to: '/login',
    icon: 'mdi-login'
  },
  {
    title: 'お問合せ',
    to: '/login/contact',
    icon: 'mdi-file-document-outline'
  }
]

const footerLinks = [
  {
    title: 'システムの紹介',
    to: '/',
    icon: 'mdi-home'
  },
  {
    title: '無料会員登録',
    to: '/regist',
    icon: 'mdi-office-building-plus'
  },
  {
    title: 'ログイン',
    to: '/login',
    icon: 'mdi-login'
  },
  {
    title: 'お問合せ',
    to: '/login/contact',
    icon: 'mdi-file-document-outline'
  }
]
const showDrawer = ref(false)
</script>

<template>
  <v-app fluid>
    <v-app-bar color="primary" app dark class="px-md-6">
      <v-app-bar-nav-icon @click="showDrawer=!showDrawer"></v-app-bar-nav-icon>
      <v-app-bar-title>
        <span>古物市場取引システム</span>
      </v-app-bar-title>
      <v-btn variant="elevated" color="info" to="/regist">無料会員登録</v-btn>
      <v-btn to="/login">ログイン</v-btn>
    </v-app-bar>
    <v-navigation-drawer v-model="showDrawer" temporary>
      <v-list>
        <v-list-item v-for="item in sidebarLinks" :key="item.to" :to="item.to">
          <v-list-item-title class="font-weight-bold" textContent="item.title" />
          <template v-slot:prepend>
            <v-icon :icon="item.icon" class="mr-6" />
          </template>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-main>
      <router-view></router-view>
      <v-container class="testzone">
      </v-container>
    </v-main>
    <v-footer color="secondary">
      <v-row justify="center">
        <v-btn v-for="link in footerLinks" :key="link" :prepend-icon="link.icon" :to="link.to" variant="text" class="mx-2" rounded="xl">
          {{ link.title }}
        </v-btn>
        <v-col class="text-center" cols="12">
          <span>{{ new Date().getFullYear() }} - 合同会社 みんふう楽器店</span>
        </v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>

<script setup>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import axios from 'axios'

const router = useRouter()
const oldPass = ref('')
const newPass = ref('')
const authPass = ref('')
const showOldPassword = ref(false)
const showNewPassword = ref(false)
const showAuthPassword = ref(false)

const handleApiError = (error) => {
  console.log('############################')
  console.log(error)
  console.log('############################')
  router.push('/login')
  // throw new Error('APIエラーが発生しました')
}
const post = async () => {
  if (newPass.value !== authPass.value) {
    return
  }
  await axios.post('https://api.minfuu.jp/users/changepass', { old_pass: oldPass.value, new_pass: newPass.value }, { withCredentials: true })
    .then((response) => response.data)
    .catch((error) => {
      handleApiError(error)
    })
}
const rule = [
  v => {
    if (v === newPass.value) return true
    return '新しいパスワードが異なっています'
  }
]
</script>

<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" md="6">
        <v-card min-width="300">
          <v-card-title>パスワード変更</v-card-title>
          <v-card-text>
            <v-text-field prepend-icon="mdi-lock" label="現在のパスワード" v-model="oldPass" :append-icon="showOldPassword ? 'mdi-eye' : 'mdi-eye-off'" :type="showOldPassword ? 'text' : 'password'" @click:append="showOldPassword = !showOldPassword"></v-text-field>
            <v-text-field prepend-icon="mdi-lock" label="新しいパスワード" v-model="newPass" :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'" :type="showNewPassword ? 'text' : 'password'" @click:append="showNewPassword = !showNewPassword"></v-text-field>
            <v-text-field prepend-icon="mdi-lock" label="新しいパスワード(確認)" v-model="authPass" :rules="rule" :append-icon="showAuthPassword ? 'mdi-eye' : 'mdi-eye-off'" :type="showAuthPassword ? 'text' : 'password'" @click:append="showAuthPassword = !showAuthPassword"></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-btn variant="elevated" color="primary" size="x-large" block @click="post">変更</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

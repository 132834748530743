<template>
  <v-container>
    <v-row justify="center">
      <v-col>
        <v-form @submit.prevent="post">
          <v-card>
            <v-card-title><h2>決済フォーム</h2></v-card-title>
            <v-card-text>
              <div ref="table"></div>
            </v-card-text>
            <v-card-actions>
              <v-btn type="submit" :disabled="!canSubmit">{{ btnText }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { loadStripe } from '@stripe/stripe-js'

const tableRef = ref(null)

onMounted(async () => {
  const stripe = await loadStripe('pk_test_51MtJWQB5YSpzl3wgOpnZja97IDR6muk6UEFwpzwRfi7s0L39TBgwNSzKl3TjDkUoABvbsek9la6LwMEupt5UlXmS00gZobtFI2')
  const { data: products } = await stripe.products.list()
  const { data: prices } = await stripe.prices.list()

  const priceByProductId = prices.data.reduce((accumlator, price) => {
    const productId = price.product
    accumlator[productId] = accumlator[productId] || []
    accumlator[productId].push(price)
    return accumlator
  }, {})
  const table = stripe.elements().create('table', {
    product: products,
    prices: priceByProductId
  })
  table.mount(tableRef.value)
})
</script>

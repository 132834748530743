<script setup>
import { ref, defineProps, defineEmits } from 'vue'
import { useRouter } from 'vue-router'
import axios from 'axios'

const apiDomain = 'https://api.minfuu.jp'

const emit = defineEmits(['close'])
const router = useRouter()
const props = defineProps({
  auctioneer: {
    type: Object
  }
})
const id = ref(props.auctioneer.id)
const number = ref(props.auctioneer.number)
const name = ref(props.auctioneer.name)
const viewName = ref(props.auctioneer.viewName)
const reading = ref(props.auctioneer.reading)
const postal = ref(props.auctioneer.postal)
const address = ref(props.auctioneer.address)
const phone = ref(props.auctioneer.phone)
const email = ref(props.auctioneer.email)
const taxCode = ref(props.auctioneer.taxCode)
const lisenceNumber = ref(props.auctioneer.lisenceNumber)

const handleApiError = (error) => {
  console.log('############################')
  console.log(error)
  console.log('############################')
  router.push('/login')
  // throw new Error('APIエラーが発生しました')
}
const post = async () => {
  if (props.auctioneer.id === -1) {
    await store()
  } else {
    await update()
  }
  emit('close')
}
const store = async () => {
  const data = {
    name: name.value,
    view_name: viewName.value,
    reading: reading.value,
    postal_code: postal.value,
    address: address.value,
    phone: phone.value,
    email: email.value,
    tax_code: taxCode.value,
    number: number.value,
    lisence_number: lisenceNumber.value
  }
  const res = await axios.post(`${apiDomain}/auctioneers`, { auctioneer: data }, { withCredentials: true })
    .then((response) => response.data)
    .catch((error) => {
      handleApiError(error)
    })
  return res.id
}
const update = async () => {
  const data = {
    id: id.value,
    name: name.value,
    view_name: viewName.value,
    reading: reading.value,
    postal_code: postal.value,
    address: address.value,
    phone: phone.value,
    email: email.value,
    tax_code: taxCode.value,
    number: number.value,
    lisence_number: lisenceNumber.value
  }
  const res = await axios.put(`${apiDomain}/auctioneers/${id.value}`, { auctioneer: data }, { withCredentials: true })
    .then((response) => response.data)
    .catch((error) => {
      handleApiError(error)
    })
  return res.id
}
</script>

<template>
  <v-card>
    <v-card-title><h2>参加者登録</h2></v-card-title>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="2">
            <v-text-field v-model="number" label="番号" variant="outlined"></v-text-field>
          </v-col>
          <v-col>
            <v-text-field v-model="name" label="正式名称" variant="outlined"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field v-model="viewName" label="略名称" variant="outlined"></v-text-field>
          </v-col>
          <v-col>
            <v-text-field v-model="reading" label="読み仮名" variant="outlined"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3">
            <v-text-field v-model="postal" label="郵便番号" variant="outlined"></v-text-field>
          </v-col>
          <v-col>
            <v-text-field v-model="address" label="住所" variant="outlined"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field v-model="lisenceNumber" label="古物商許可番号" variant="outlined" hint="○○県xxxxxxxxxx号"></v-text-field>
          </v-col>
          <v-col>
            <v-text-field v-model="taxCode" label="インボイス登録番号" variant="outlined"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
        </v-row>
        <v-row>
          <v-col cols="12" md="4">
            <v-text-field v-model="phone" label="電話番号" variant="outlined"></v-text-field>
          </v-col>
          <v-col>
            <v-text-field v-model="email" label="メール" variant="outlined"></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn variant="outlined" prepend-icon="mdi-account-check" size="x-large" color="primary" block @click="post">登録</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script setup>
import { computed, defineProps, ref, defineEmits } from 'vue'

const props = defineProps({
  auctioneers: Array,
  numberInput: Boolean,
  modelValue: Number,
  label: String
})
const inputCode = ref(props.modelValue)
const emit = defineEmits(['update:modelValue'])

const selectedId = computed({
  get: () => props.modelValue,
  set: (value) => {
    emit('update:modelValue', value)
  }
})
const changeCode = () => {
  props.auctioneers.map(x => console.log(typeof x.number))
  const selected = props.auctioneers.filter(x => parseInt(x.number) === parseInt(inputCode.value))
  if (selected.length) {
    selectedId.value = selected[0].id
  }
  inputCode.value = ''
}
</script>

<template>
  <v-row no-gutters v-if="numberInput">
    <v-col cols="2">
      <v-text-field variant="underlined" v-model="inputCode" label="番号" @change="changeCode"></v-text-field>
    </v-col>
    <v-col>
      <v-select variant="outlined" v-model="selectedId" :label="props.label" :items="props.auctioneers" item-title="name" item-value="id">
      </v-select>
    </v-col>
  </v-row>
  <v-row v-else no-gutters>
    <v-select variant="outlined" v-model="selectedId" :label="props.label" :items="props.auctioneers" item-title="name" item-value="id">
    </v-select>
  </v-row>
</template>
